import React, { useState } from 'react';
import axios from 'axios';
import { Button, Alert, Card } from 'react-bootstrap';
import CajaImage from '../../../assets/CerrarCaja.png';  
import FallbackImage from '../../../assets/caja.png'; 
import './Caja.css';
import SucursalesEmp from '../../RolEmpleado/SucursalesEmp/SucursalesEmp';
import OpenModal from './OpenModal';

const AbrirCaja = () => {
  const [mensaje, setMensaje] = useState('');
  const [error, setError] = useState('');
  const [imageSrc, setImageSrc] = useState(CajaImage); 
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedSucursalId, setSelectedSucursalId] = useState('');
  const [selectedSucursalName, setSelectedSucursalName] = useState('');

  const handleImageError = () => {
    setImageSrc(FallbackImage);
  };

  const handleAbrirCaja = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/public/v2/OpenBox`, {
        idBranches: selectedSucursalId
      }, {
        headers: {
          'Content-Type': 'application/json',
          'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token')
        }
      });

      if (response.status === 200 && response.data.ok) {
        setMensaje(response.data.msg);
        setError('');
      } else {
        setError(response.data.msg);
        setMensaje('');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setError(error.response.data.msg);
        setMensaje('');
      } else {
        setError('Error al abrir la caja');
        setMensaje('');
      }
    }
  };

  const handleShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmAbrirCaja = () => {
    handleCloseConfirmModal();
    handleAbrirCaja();
  };

  return (
    <div className="caja-container">
      <Card className="caja-card">
        <h1 className='caja-header'>Abrir Caja</h1>
        <SucursalesEmp 
          onSucursalSelect={(id, name) => {
            setSelectedSucursalId(id);
            setSelectedSucursalName(name);
          }} 
        />
        {mensaje && <Alert variant="success" className="mt-4">{mensaje}</Alert>}
        <div className="text-center mt-4">
          {selectedSucursalName && <h2 className='caja-subheader'>Sucursal seleccionada: {selectedSucursalName}</h2>}
          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
          <img
            src={imageSrc}
            alt="Abrir Caja"
            className="img-fluid caja-image mt-4"
            onError={handleImageError}
          />
          <Button
            variant="primary"
            onClick={handleShowConfirmModal}
            className="caja-button"
            disabled={!selectedSucursalId}
          >
            Abrir Caja
          </Button>
        </div>
      </Card>
      
      <OpenModal 
        show={showConfirmModal} 
        handleClose={handleCloseConfirmModal} 
        handleConfirm={handleConfirmAbrirCaja}
        sucursalName={selectedSucursalName}  
      />
      
    </div>
  );
};

export default AbrirCaja;
