import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchCajas = (page = 1) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_URL}/public/v2/GSuc/ListBoxBranch`, {
          page: page,
        }, {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        });

        setData(response.data); // Almacenamos toda la respuesta
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page]); // Agregar page como dependencia

  return { data, loading, error };
};

export default useFetchCajas;
