import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert, Card, Spinner } from 'react-bootstrap';
import { FaUser, FaLock } from 'react-icons/fa';
import logo from '../../assets/fondo-susa-sinfondo.png';
import ModalNewPassword from './ModalNewPassword';

function Login() {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [codeSentMessage, setCodeSentMessage] = useState(''); 
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSuccessMessage('');
    setCodeSentMessage('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/public/v2/Login`, { user, password });
  
      const { token, data } = response.data;
      const { role, nameShop, idBranches  } = data;
    //  console.log('Datos recibidos:', data);

      if (role) {
        // Si el rol está presente en la respuesta, lo guardamos en localStorage
        localStorage.setItem('role', role);
      } else if (!role && nameShop) {
        // Si no tiene rol pero tiene nameShop, asignamos comercio
       // console.log("No se encontró un rol, asignando 'comercio' porque tiene nameShop.");
        localStorage.setItem('role', 'comercio');
      } else {
        // Si no tiene ni rol ni nameShop, asignamos 'Emp'
       // console.log("No se encontró un rol ni nameShop, asignando 'Emp' por defecto.");
        localStorage.setItem('role', 'Emp');
      }
  
      // Verificar el rol asignado
      const assignedRole = localStorage.getItem('role');
      //console.log('Rol asignado:', assignedRole);
  
      if (response.status === 400 && response.data.msg === "Usuario sin contraseña") {
        await sendCode();
      } else {
        // Guardar la información común
        localStorage.setItem('token', token);
        localStorage.setItem('username', user);
        localStorage.setItem('nameShop', nameShop);
  
        if (assignedRole === 'comercio') {
          localStorage.setItem('cuit', data.cuit);
          localStorage.setItem('correo', data.email);
          localStorage.setItem('nombre', data.name);
          localStorage.setItem('comercioId', data.idShop);
          localStorage.setItem('province', data.province);
          localStorage.setItem('city', data.city);
          localStorage.setItem('postalCode', data.postalCode);
          localStorage.setItem('address', data.address);
          localStorage.setItem('riskProfile', data.riskProfile);
          localStorage.setItem('cvu', data.cvu);
          localStorage.setItem('currency', data.currency);
          localStorage.setItem('currentBalance', data.currentBalance);
          localStorage.setItem('username', user);
          localStorage.setItem('nameShop', nameShop);
          console.log('nameShop', nameShop, 'usuario', user);
          navigate('/inicio');
        } else if (assignedRole === 'Emp') {
          // Guardar información relevante para empleado
          localStorage.setItem('empleadoId', data._id);
          localStorage.setItem('nombre', data.name);
          localStorage.setItem('apellido', data.lastName);
          localStorage.setItem('cuit', data.cuit);
          localStorage.setItem('correo', data.email);
          navigate('/mi-comercio');
        } else if (assignedRole === 'audit') {
          // Guardar información relevante para auditor
          localStorage.setItem('nombre', data.name);
          localStorage.setItem('correo', data.email);
          navigate('/inicio-auditor');
        } else if (assignedRole === 'gsuc') {
          // Guardar información relevante para gerente
          localStorage.setItem('nombre', data.name);
          localStorage.setItem('correo', data.email);
          localStorage.setItem('idBranches', idBranches);
          navigate('/mi-comercio');
        }
  
        localStorage.setItem('redirected', 'true');
      }
    } catch (error) {
      setIsLoading(false); 
        if (error.response && error.response.status === 400 && error.response.data.msg === "Usuario sin contraseña") {
            await sendCode(); 
        } else if (error.response && error.response.data && error.response.data.msg) {
            setError(error.response.data.msg);
        } else {
            setError('Usuario o contraseña incorrectos. Inténtalo de nuevo.');
        }
      //  console.error('Login error', error);
    } 
};


  const sendCode = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'SECRET': process.env.REACT_APP_SECRET 
      };

      const body = { user }; // Aquí se define el cuerpo de la solicitud

      // Imprimir en consola los datos que se envían
     // console.log('Enviando código para usuario:', body);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/public/v2/SendCode`,
        body,
        { headers }
      );

      if (response.data.ok) {
        setCodeSentMessage(
          <>
            {response.data.msg} 
            <Button variant="link" onClick={() => setShowModal(true)}>
              Ingresá código
            </Button>
          </>
        );
        setShowModal(true); 
      } else {
        setError('Error al enviar el código. Intenta de nuevo.');
      }
    } catch (error) {
      setError('Error al enviar el código. Intenta de nuevo.');
    //  console.error('SendCode error', error);
    }
  };

  const handleModalClose = (isPasswordChanged) => {
    setShowModal(false);
    if (isPasswordChanged) {
      setSuccessMessage('Contraseña creada exitosamente.');
      setCodeSentMessage('');
    } else {
      setSuccessMessage('');
    }
  };

  return (
    <>
      <Container fluid className="d-flex align-items-center justify-content-center min-vh-100">
        <Row className="justify-content-center w-100">
          <Col md="6" lg="4">
            <Card
              className="shadow-lg p-4 mx-auto"
              style={{
                borderRadius: '15px',
                maxWidth: '100%',
                minHeight: '500px',
                padding: '30px',
              }}
            >
              <div className="text-center mb-4">
                <img src={logo} alt="Bienvenido" className="img-fluid" style={{ maxWidth: '80%', height: 'auto' }} />
              </div>
              {error && <Alert variant="danger">{error}</Alert>}
              {codeSentMessage && <Alert variant="success">{codeSentMessage}</Alert>}
              {successMessage && <Alert variant="success">{successMessage}</Alert>}
              <Form onSubmit={handleLogin}>
                <Form.Group controlId="formBasicUsername" className="position-relative mb-4">
                  <Form.Label>Usuario</Form.Label>
                  <div className="input-group">
                    <span className="input-group-text"><FaUser /></span>
                    <Form.Control
                      type="text"
                      placeholder="Enter username"
                      value={user}
                      onChange={(e) => setUser(e.target.value)}
                    />
                  </div>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="position-relative mb-3">
                  <Form.Label>Contraseña</Form.Label>
                  <div className="input-group">
                    <span className="input-group-text"><FaLock /></span>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 mt-3"
                  style={{ backgroundColor: '#FF5100', border: 'none' }}
                  onMouseEnter={(e) => e.target.style.backgroundColor = '#FF7043'}
                  onMouseLeave={(e) => e.target.style.backgroundColor = '#FF5100'}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Iniciar sesión'}
                </Button>

                <Button 
                 variant="link" 
                 onClick={sendCode} 
             className="w-100 text-center mt-5"
                    >
               ¿Olvidaste tu contraseña?
               </Button>

              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalNewPassword show={showModal} handleClose={handleModalClose} user={user} />
    </>
  );
}

export default Login;
