import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import './SucursalesEmp.css';

const SucursalesEmp = ({ onSucursalSelect }) => {
  const [sucursales, setSucursales] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSucursales = async () => {
      const token = localStorage.getItem('token');
      const API_URL = process.env.REACT_APP_URL;

      try {
        const response = await axios.get(
          `${API_URL}/public/v2/List/Branches`,
          {
            headers: {
              'SERVICIOS-URBANOS-TOKEN': token,
            },
          }
        );
        if (response.data.ok) {
          setSucursales(response.data.msg);
        } else {
          setError('No se pudo obtener la lista de sucursales.');
        }
      } catch (err) {
        setError('Error al obtener la lista de sucursales.');
        console.error('Error:', err);
      }
    };

    fetchSucursales();
  }, []);

  const handleSelectChange = (e) => {
    const selectedSucursal = sucursales.find(sucursal => sucursal.id === e.target.value);
    if (selectedSucursal) {
      onSucursalSelect(selectedSucursal.id, selectedSucursal.name);
    }
  };

  return (
    <div className="sucursales-select-container">
      <Form.Group controlId="selectSucursal">
       
        <Form.Control as="select" onChange={handleSelectChange}>
          <option value="">Selecciona una sucursal</option>
          {sucursales.map(sucursal => (
            <option key={sucursal.id} value={sucursal.id}>
              {sucursal.name} ({sucursal.state ? 'Activo' : 'Inactivo'})
            </option>
          ))}
        </Form.Control>
        {error && <p className="text-danger mt-2">{error}</p>}
      </Form.Group>
    </div>
  );
};

export default SucursalesEmp;
