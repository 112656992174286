import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!token) {
      setIsInitialized(true); // Si no hay token, marcar la inicialización como completa para manejar la redirección a login
    } else {
      // Comprobamos si el usuario ya ha sido redirigido una vez
      const isRedirected = localStorage.getItem('redirected');

      if (!isRedirected) {
        if (role === 'comercio') {
          localStorage.setItem('redirected', 'true');
          window.location.href = '/inicio'; // Redirige al inicio para el comercio
        } else if (role === 'empleado') {
          localStorage.setItem('redirected', 'true');
          window.location.href = '/mi-comercio'; // Redirige a mi-comercio para el empleado
        }
      } else {
        if (role === 'comercio' && window.location.pathname !== '/inicio') {
          // Corrige la ruta para el comercio si no está en la página correcta
          window.location.href = '/inicio';
        } else if (role === 'empleado' && window.location.pathname !== '/mi-comercio') {
          // Corrige la ruta para el empleado si no está en la página correcta
          window.location.href = '/mi-comercio';
        } else {
          setIsInitialized(true); // Marcamos que el componente ha terminado de inicializarse
        }
      }
    }
  }, [token, role]);

  // Redirigir al login si no hay token
  if (!token) {
    return <Navigate to="/login" />;
  }

  // No renderizar los hijos hasta que la lógica de inicialización esté completa
  if (!isInitialized) {
    return null; // Mostrar un cargador o nada mientras se inicializa
  }

  return children;
}

export default PrivateRoute;
