import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

function SearchForm({ handleSearch, setSearchDate }) {
  const [localSearchDate, setLocalSearchDate] = useState('');

  const handleDateChange = (e) => {
    const date = e.target.value;
    setLocalSearchDate(date);
    setSearchDate(date);
  };

  return (
    <>
      <Form.Group controlId="formDate" className="mb-3">
        <Form.Label>Fecha (opcional)</Form.Label>
        <Form.Control type="date" value={localSearchDate} onChange={handleDateChange} />
      </Form.Group>
      <Button 
        variant="secondary" 
        onClick={handleSearch} 
        className="ButtonsComprobante"
      >
        Buscar
      </Button>
    </>
  );
}

export default SearchForm;
