import React from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import logo from '../../assets/logo-susa-corto.png';
import './Header.css';

function Header() {
  const nombreTienda = localStorage.getItem('nombre'); 

  return (
    <Navbar className="header-navbar">
      <Container fluid className="d-flex justify-content-between align-items-center">
        <Row className="align-items-center">
          <Col className="logo-col">
            <img
              src={logo}
              height="40"  
              className="d-inline-block align-top "
              alt="Logo" 
            />
          </Col>
          <Col className="username-col">
            {nombreTienda && (
              <Navbar.Text className="username-text">
                Bienvenido, {nombreTienda}
              </Navbar.Text>
            )}
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default Header;
