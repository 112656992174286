import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faReceipt, faStore, faSignOutAlt, faCogs, faList, faWallet, faCashRegister, faUserPlus, faBuilding, faUsers } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isSaldosOpen, setIsSaldosOpen] = useState(false);
  const [isEmpleadosOpen, setIsEmpleadosOpen] = useState(false);
  const [isSucursalesOpen, setIsSucursalesOpen] = useState(false); 
  const role = localStorage.getItem('role');
  const navigate = useNavigate();

  const handleAdminClick = () => {
    setIsAdminOpen(!isAdminOpen);
  };

  const handleSaldosClick = () => {
    setIsSaldosOpen(!isSaldosOpen);
  };

  const handleEmpleadosClick = () => {
    setIsEmpleadosOpen(!isEmpleadosOpen);
  };

  const handleSucursalesClick = () => {
    setIsSucursalesOpen(!isSucursalesOpen);
  };

  const handleLogout = () => {
    navigate('/funciones-administrativas/logout'); 
  };

  return (
    <div className="sidebar">
      <Nav className="flex-column">
        {role === 'comercio' ? (
          <>
            <LinkContainer to="/inicio">
              <Nav.Link>
                <FontAwesomeIcon icon={faStore} className="fa-icon" /> Inicio
              </Nav.Link>
            </LinkContainer>
            {/*<LinkContainer to="/comprobantes">
              <Nav.Link>
                <FontAwesomeIcon icon={faReceipt} className="fa-icon" /> Comprobantes
              </Nav.Link>
        </LinkContainer>*/}

            <Nav.Item>
              <Nav.Link onClick={handleEmpleadosClick}>
                <FontAwesomeIcon icon={faUsers} className="fa-icon" /> Empleados
              </Nav.Link>
            </Nav.Item>
            {isEmpleadosOpen && (
              <>
              <LinkContainer to="/agregar-empleado">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faUserPlus} className="fa-icon" /> Agregar empleados
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/lista-empleados">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faList} className="fa-icon" /> Lista Empleados
                  </Nav.Link>
                </LinkContainer>
                
              </>
            )}

            <Nav.Item>
              <Nav.Link onClick={handleSucursalesClick}>
                <FontAwesomeIcon icon={faBuilding} className="fa-icon" /> Sucursales
              </Nav.Link>
            </Nav.Item>
            {isSucursalesOpen && (
              <>
                <LinkContainer to="/agregar-sucursal">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faBuilding} className="fa-icon" /> Agregar sucursales
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/lista-de-sucursales">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faList} className="fa-icon" /> Lista Sucursales
                  </Nav.Link>
            </LinkContainer>
              </>
            )}
          </>
        ) : (
          <>
            <LinkContainer to="/mi-comercio">
              <Nav.Link>
                <FontAwesomeIcon icon={faStore} className="fa-icon" /> Inicio
              </Nav.Link>
            </LinkContainer>
            <Nav.Item>
              <Nav.Link onClick={handleSaldosClick}>
                <FontAwesomeIcon icon={faWallet} className="fa-icon" /> Saldos
              </Nav.Link>
            </Nav.Item>
            {isSaldosOpen && (
              <>
                <LinkContainer to="/recarga-saldo">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faMoneyBillWave} className="fa-icon" /> Nuevas recargas
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/lista-de-saldos">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faList} className="fa-icon" /> Lista de movimientos
                  </Nav.Link>
                </LinkContainer>
              </>
            )}
            {/*<LinkContainer to="/comprobantes">
              <Nav.Link>
                <FontAwesomeIcon icon={faReceipt} className="fa-icon" /> Comprobantes
              </Nav.Link>
          </LinkContainer>*/}
            {/*<LinkContainer to="/lista-sucursales">
              <Nav.Link>
                <FontAwesomeIcon icon={faBuilding} className="fa-icon" /> Sucursales
              </Nav.Link>
          </LinkContainer>*/}
            
            <Nav.Item>
              <Nav.Link onClick={handleAdminClick}>
                <FontAwesomeIcon icon={faCogs} className="fa-icon" /> Funciones Administrativas
              </Nav.Link>
            </Nav.Item>
            {isAdminOpen && (
              <>
              <LinkContainer to="/funciones-administrativas/abrir-caja">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faCashRegister} className="fa-icon" /> Abrir Caja
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/funciones-administrativas/cerrar-caja">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faCashRegister} className="fa-icon" /> Cerrar Caja
                  </Nav.Link>
                </LinkContainer>
                
              </>
            )}
          </>
        )}
       
        <Nav.Item>
          <LinkContainer to="/funciones-administrativas/logout">
            <Nav.Link onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} className="fa-icon" /> Cerrar sesión
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      </Nav>
    </div>
  );
}

export default Sidebar;
