import React, { useEffect } from 'react';
import { Card, Button, ListGroup, Spinner } from 'react-bootstrap';
import './Empleados.css';

function DetalleCaja({ detalle, idBox, onVerHistorico, loading }) {
  useEffect(() => {
    console.log('idBox utilizado para la solicitud:', idBox); 
  }, [idBox]);

  // Si está cargando, muestra un spinner
  if (loading) {
    return <Spinner animation="border" role="status"><span className="sr-only">Cargando...</span></Spinner>;
  }

  // Si no hay detalles disponibles, muestra un mensaje de carga
  if (!detalle) {
    return <p>Cargando detalles...</p>;
  }

  return (
    <Card className="mt-4">
      <Card.Body>
        <ListGroup variant="flush">
          <ListGroup.Item><strong>Nombre del Comercio:</strong> {detalle.Shop?.nameShop}</ListGroup.Item>
          <ListGroup.Item><strong>CVU:</strong> {detalle.Shop?.cvu}</ListGroup.Item>
          
          <ListGroup.Item>
            <strong>Empleados Asociados:</strong>
            {detalle.Emp.map((empleado, index) => (
              <div key={index} className="mt-2">
                <span><strong>Nombre:</strong> {empleado.name} {empleado.lastName}</span><br />
                <span><strong>Email:</strong> {empleado.email}</span><br />
                <span><strong>CUIT:</strong> {empleado.cuit}</span>
              </div>
            ))}
          </ListGroup.Item>

          <ListGroup.Item>
            <strong>Sucursales Asociadas:</strong>
            {detalle.Suc && detalle.Suc.length > 0 ? (
              detalle.Suc.map((sucursal, index) => (
                <div key={index} className="mt-2">
                  <span><strong>Nombre de Sucursal:</strong> {sucursal.name}</span><br />
                  <span><strong>ID:</strong> {sucursal.id}</span><br />
                  <span><strong>Estado:</strong> {sucursal.state ? 'Activo' : 'Inactivo'}</span>
                </div>
              ))
            ) : (
              <p>No hay sucursales asociadas.</p>
            )}
          </ListGroup.Item>
        </ListGroup>
        <Button variant="link" onClick={() => onVerHistorico(idBox)}>
          Ver Histórico
        </Button>
      </Card.Body>
    </Card>
  );
}

export default DetalleCaja;
