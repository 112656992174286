import React, { useState, useEffect } from 'react';
import { Table, Button, Spinner } from 'react-bootstrap';
import './Empleados.css';

function TablaHistorial({ movimientos, loading }) {
  const [currentPage, setCurrentPage] = useState(1);
  const movimientosPerPage = 5;

  const indexOfLastMovimiento = currentPage * movimientosPerPage;
  const indexOfFirstMovimiento = indexOfLastMovimiento - movimientosPerPage;
  const currentMovimientos = movimientos.slice(indexOfFirstMovimiento, indexOfLastMovimiento);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Resetea la página actual cada vez que se actualiza la lista de movimientos
  useEffect(() => {
    setCurrentPage(1);
  }, [movimientos]);

  // Muestra un spinner de carga mientras los datos se están cargando
  if (loading) {
    return (
      <div className="mt-4 d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Cargando...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="mt-4">
      {currentMovimientos.length > 0 ? (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>CVU</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>CUIT</th>
                <th>Balance</th>
                <th>Efectivo en Caja</th>
                <th>Tipo de Movimiento</th>
              </tr>
            </thead>
            <tbody>
              {currentMovimientos.map((movimiento, index) => (
                <tr key={index}>
                  <td>{movimiento.movimiento.cvu}</td>
                  <td>{movimiento.movimiento.nameOrig}</td>
                  <td>{movimiento.movimiento.lastNameOrig}</td>
                  <td>{movimiento.movimiento.cuit}</td>
                  <td>{movimiento.movimiento.balance}</td>
                  <td>{movimiento.movimiento.boxCash}</td>
                  <td>{movimiento.movimiento.types}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between">
            <Button
              className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <Button
              className={`pagination-button ${indexOfLastMovimiento >= movimientos.length ? 'disabled' : ''}`}
              onClick={handleNextPage}
              disabled={indexOfLastMovimiento >= movimientos.length}
            >
              Siguiente
            </Button>
          </div>
        </>
      ) : (
        <p>No se encontraron movimientos.</p>
      )}
    </div>
  );
}

export default TablaHistorial;
