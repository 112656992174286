import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import axios from 'axios';

function AddEmpleado() {
  const [lastName, setLastName] = useState('');
  const [name, setName] = useState('');
  const [cuit, setCuit] = useState('');
  const [email, setEmail] = useState('');
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [branch, setBranch] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const fetchSucursales = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_URL}/public/v2/List/Branches`, {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        });
  
        if (response.data.ok) {
          setSucursales(response.data.msg);
        } else {
          setError('Error al cargar sucursales.');
        }
      } catch (err) {
        setError('Error al cargar sucursales.');
      }
    };
  
    fetchSucursales();
  }, []);
  

  useEffect(() => {
    const isValid = lastName && name && cuit && email && user && pass;
    setIsFormValid(isValid);
  }, [lastName, name, cuit, email, user, pass]);

  const handleAddEmpleado = async (e) => {
    e.preventDefault();
    const idShop = localStorage.getItem('comercioId'); 
    if (!idShop) {
      setError('ID de la tienda no encontrado.');
      return;
    }
  
    if (!isFormValid || !branch || !role) {  // Agregamos la validación de branch y role
      setError('Por favor, complete todos los campos.');
      return;
    }
  
    setIsLoading(true);  // Inicia el estado de carga
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/public/v2/Add/Employees`, {
        idShop,
        last_name: lastName,
        name,
        cuit,
        email,
        user,
        pass,
        branch,
        role
      }, {
        headers: {
          'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token'),
        }
      });
  
      if (response.data.ok) {
        setSuccess('Empleado agregado con éxito.');
        setError('');
        setLastName('');
        setName('');
        setCuit('');
        setEmail('');
        setUser('');
        setPass('');
        setBranch('');
        setRole('');
      } else {
        setError(response.data.msg || 'Error al agregar el empleado.');
      }
    } catch (err) {
      setError('Error al agregar el empleado.');
      //console.error('Error al agregar el empleado:', err);
    } finally {
      setIsLoading(false);  // Finaliza el estado de carga
    }
  };
  

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
      <Row className="w-100">
        <Col md={{ span: 8, offset: 2 }}> 
          <Card className="shadow-lg" style={{ border: 'none', borderRadius: '15px', overflow: 'hidden' }}>
            <Card.Body className="p-4">
              <h2 className="mb-4 text-center" style={{ color:'#fd6b0c'}}>Agregar Empleado</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Form onSubmit={handleAddEmpleado}>
                <Form.Group controlId="formLastName" className="mb-3">
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el apellido"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formName" className="mb-3">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el nombre"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formCuit" className="mb-3">
                  <Form.Label>CUIT</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el CUIT"
                    value={cuit}
                    onChange={(e) => setCuit(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formBranch" className="mb-3">
                <Form.Label>Sucursal</Form.Label>
                <Form.Control
                as="select"
                value={branch}
                 onChange={(e) => setBranch(e.target.value)}
                required
                >
                <option value="">Seleccione una sucursal</option>
                    {sucursales.map((sucursal) => (
                   <option key={sucursal.id} value={sucursal.id}>
                  {sucursal.name}
                  </option>
                    ))}
              </Form.Control>
              </Form.Group>

              <Form.Group controlId="formRole" className="mb-3">
  <Form.Label>Rol</Form.Label>
  <Form.Control
    as="select"
    value={role}
    onChange={(e) => setRole(e.target.value)}
    required
  >
    <option value="">Seleccione un rol</option>
    <option value="audit">Auditor</option>
    <option value="gsuc">Gerente</option>
    <option value="Emp">Empleado</option>
  </Form.Control>
</Form.Group>


                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Correo Electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Ingrese el correo electrónico"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formUser" className="mb-3">
                  <Form.Label>Usuario</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el nombre de usuario"
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formPass" className="mb-4">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Ingrese la contraseña"
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                  />
                </Form.Group>

                <Button 
                variant="primary" 
                type="submit" 
                style={{ backgroundColor: '#118a80', border: 'none', transition: 'background-color 0.3s' }} 
                onMouseEnter={(e) => e.target.style.backgroundColor = '#FF7043'}
                onMouseLeave={(e) => e.target.style.backgroundColor = '#118a80'}
                disabled={!isFormValid || isLoading}  // Deshabilitar el botón mientras está cargando
                block
                >
  {isLoading ? 'Añadiendo empleado...' : 'Agregar Empleado'}
</Button>

              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AddEmpleado;
