import React, { useState } from 'react';
import { Table, Card, Button } from 'react-bootstrap';
import useFetchCajas from '../Hooks/Gerente/useFetchTodasLasCajas';
import CajaDetailsModal from './DetalleCajaModal'; 
import './TodasLasCajas.css'; 

function TodasLasCajas() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading, error } = useFetchCajas(currentPage);
  const [selectedCaja, setSelectedCaja] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (caja) => {
    setSelectedCaja(caja);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNextPage = () => {
    if (currentPage < data.total) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="todas-cajas-container">
      <Card className="todas-cajas-card">
        <Card.Body>
          <h2 className="todas-cajas-header">Lista de Cajas</h2>
          {loading && <p>Cargando...</p>}
          {error && <p>Error al cargar las cajas: {error}</p>}
          
          {!loading && !error && (
            <>
              <Table striped bordered hover className="todas-cajas-table">
                <thead>
                  <tr>
                    <th>Id Caja</th>
                    <th>Empleado</th>
                    <th>Comercio</th>
                    <th>Sucursal</th>
                    <th>Acciones</th> 
                  </tr>
                </thead>
                <tbody>
                  {data && data.msg.map((caja) => (
                    <tr key={caja._id}>
                      <td>{caja.idBox}</td>
                      <td>{caja.empleado.name} {caja.empleado.lastName}</td>
                      <td>{caja.comercio.nameShop}</td>
                      <td>{caja.comercio.branches.name}</td>
                      <td>
                        <a href="#" className="link-ver-mas" onClick={() => handleShowModal(caja)}>
                          Ver Detalle
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* Botones de paginación personalizados */}
              <div className="lista-saldos-buttons">
                <Button
                  variant="secondary"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Anterior
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleNextPage}
                  disabled={currentPage === data.total}
                >
                  Siguiente
                </Button>
              </div>
            </>
          )}

          {/* Modal para mostrar los detalles */}
          <CajaDetailsModal 
            show={showModal} 
            handleClose={handleCloseModal} 
            caja={selectedCaja} 
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default TodasLasCajas;
