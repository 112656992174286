import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Routes, Route, Navigate } from 'react-router-dom';
import RecargaSaldo from '../RolEmpleado/RecargaSaldo/RecargaSaldo';
import Saldo from '../Saldo/Saldo';
import Comprobantes from '../Comprobantes/Comprobantes';
import CerrarCaja from '../FuncionesAdministrativas/Caja/CerrarCaja';
import Header from '../Header/Header';
import Logout from '../FuncionesAdministrativas/Logout';
import Sidebar from '../Sidebar/Sidebar';
import MiComercio from '../MiComercio/MiComercio';
import ListaDeSaldos from '../RolEmpleado/ListaDeSaldos/ListaDeSaldos';
import Empleados from '../RolComercio/Empleados/ListaEmpleados.js/ListaEmpleados'
import AddEmpleado from '../RolComercio/Empleados/AgregarEmpleado.js/AddEmpleado';
import AddSucursal from '../RolComercio/AddSucursal.js/AddSucursal';
import SucursalesEmp from '../RolEmpleado/SucursalesEmp/SucursalesEmp';
import AbrirCaja from '../FuncionesAdministrativas/Caja/AbrirCaja';
import SucursalesComercio from '../RolComercio/Sucursales/SucursalesComercio';
import ListaSucursales from '../RolComercio/ListaSucursales/ListaSucursales';
import Inicio from '../RolComercio/Inicio/Inicio';


import './Dashboard.css'; 

function Dashboard() {
  return (
    <Container fluid>
      <Row>
        <Header />
      </Row>
      <Row>
        <Col xs={2}>
          <Sidebar />
        </Col>
        <Col xs={10} className="main-content" id="page-content-wrapper">
          <Routes>
            <Route path="/recarga-saldo" element={<RecargaSaldo />} />
            <Route path="/saldo" element={<Saldo />} />
            <Route path="/comprobantes" element={<Comprobantes />} />
            <Route path="/funciones-administrativas/cerrar-caja" element={<CerrarCaja />} />
            <Route path="/funciones-administrativas/abrir-caja" element={<AbrirCaja />} />
            <Route path="*" element={<Navigate to="/mi-comercio" />} />
            <Route path="/funciones-administrativas/logout" element={<Logout />} />
            <Route path="/mi-comercio" element={<MiComercio />} />
            <Route path="/lista-de-saldos" element={<ListaDeSaldos />} />
            <Route path='/lista-empleados' element={<Empleados />}/>
            <Route path='/agregar-empleado' element={<AddEmpleado />}/>
            <Route path='/agregar-sucursal' element={<AddSucursal />}/>
            {/*<Route path='/lista-sucursales' element={<SucursalesEmp />}/>*/}
            <Route path='/lista-sucursales' element={<SucursalesComercio />}/>
            <Route path='/lista-de-sucursales' element={<ListaSucursales />} />
        
            <Route path='inicio' element={<Inicio />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
