import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import './MiComercio.css';
import ComercioImage from '../../assets/Home1.png'; 
import Saldo from '../Saldo/Saldo';

function MiComercio() {
  const username = localStorage.getItem('username');
  const nameShop = localStorage.getItem('nombre');
  const cuit = localStorage.getItem('cuit');
  const email = localStorage.getItem('correo');
  const comercioId = localStorage.getItem('_id'); 

  return (
    <Container className="mi-comercio-container">
      <Card className="mi-comercio-card">
        <Row className="justify-content-md-center">
          <Col md="12" className="text-center">
            <img src={ComercioImage} alt="Comercio" className="img-fluid mb-4 mi-comercio-img" />
            <h2 className="mi-comercio-header">Bienvenido al POS Servicios Urbanos</h2>
            <div className="mi-comercio-info">
              <p><strong>Usuario:</strong> {username}</p>
            
              {/*<p><strong>CUIT:</strong> {cuit}</p>
              <p><strong>Email:</strong> {email}</p>*/}
              <p><strong>Nombre:</strong> {nameShop}</p>
            </div>
          </Col>
        </Row>
        <Saldo />
      </Card>
    </Container>
  );
}

export default MiComercio;
