import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchCajas = (idBranch) => {
  const [cajas, setCajas] = useState([]);
  const [nombreSucursal, setNombreSucursal] = useState('');
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false); 
  const [error, setError] = useState(null);
  
  const fetchCajas = async (idBranches) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Token no disponible.');
      setLoading(false);
      return;
    }
    
    const API_URL = process.env.REACT_APP_URL;

    setIsFetching(true);  
    setError(null); // Limpiar error antes de la nueva consulta
    try {
      const response = await axios.post(
        `${API_URL}/public/v2/Audit/ListBranches`,
        { idBranches },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );

      //console.log("Respuesta obtenida:", response.data);
      
      const sucursal = response.data.msg.find(branch => branch.id === idBranches);
      setNombreSucursal(sucursal ? sucursal.name : 'Sucursal desconocida');
      setCajas(response.data.msg || []);
    } catch (err) {
      setError('Error al obtener las cajas de la sucursal.');
      //console.error("Error en la solicitud:", err);
    } finally {
      setIsFetching(false);
      setLoading(false);
    }
  };

  return { cajas, nombreSucursal, loading, isFetching, error, refetch: fetchCajas, setError };
};

export default useFetchCajas;
