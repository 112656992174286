import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Card, Button } from 'react-bootstrap';
import './ListaDeSaldos.css';
import DetalleSaldoModal from './DetalleSaldoModal';

const ListaDeSaldos = () => {
  const [saldos, setSaldos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [detalleSaldo, setDetalleSaldo] = useState(null);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchSaldos(currentPage);
  }, [currentPage]);

  const fetchSaldos = async (page) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/public/v1/Shops/List/PayCredit`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token'),
            'page': page,
            'itemsPerPage': itemsPerPage
          }
        }
      );
      if (response.data && response.data.lista) {
        setSaldos(response.data.lista);
        setTotalPages(response.data.totalPage || 1);
      } else {
        setError('No se encontraron saldos.');
      }
    } catch (error) {
      //console.error('Error fetching saldos:', error);
      setError('Error al obtener los saldos.');
    }
  };

  const fetchDetalleSaldo = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/public/v1/Shops/List/OneMov`,
        { _id: id },
        {
          headers: {
            'Content-Type': 'application/json',
            'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token')
          }
        }
      );
      //console.log('Response from OneMov:', response.data); 
      if (response.data && response.data.lista) {
        setDetalleSaldo(response.data.lista);
        setShowModal(true);
      } else {
        setError('No se encontró el detalle del saldo.');
      }
    } catch (error) {
      //console.error('Error fetching detalle saldo:', error);
      setError('Error al obtener el detalle del saldo.');
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const year = String(date.getUTCFullYear()).slice(-2); 
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="lista-saldos-container">
      <Card className="lista-saldos-card">
        <Card.Body>
          <h2 className="lista-saldos-header">Lista de movimientos</h2>
          {error && <p className="text-danger">{error}</p>}
          <Table striped bordered hover className="lista-saldos-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Fecha</th>
                <th>Monto</th>
                <th>Tipo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {saldos.map((saldo, index) => (
                <tr key={saldo._id}>
                  <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                  <td>{formatDate(saldo.timestamp)}</td>
                  <td>${saldo.movimiento.balance?.toFixed(2) || 'N/A'}</td>
                  <td>{saldo.movimiento.types}</td>
                  <td>
                    <a href="#" className="link-ver-mas" onClick={() => fetchDetalleSaldo(saldo._id)}>
                      Ver Más
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="lista-saldos-buttons">
            <Button
              variant="secondary"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <Button
              variant="secondary"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Siguiente
            </Button>
          </div>
        </Card.Body>
      </Card>

      {detalleSaldo && (
        <DetalleSaldoModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          detalleSaldo={detalleSaldo}
        />
      )}
    </div>
  );
};

export default ListaDeSaldos;
