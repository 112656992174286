import React, { useState, useEffect } from 'react';
import { Table, Card, Form } from 'react-bootstrap'; 
import { useParams, useNavigate } from 'react-router-dom';
import useFetchCajas from '../Hooks/Auditor/useFetchListaDeCajas';
import CajaModal from './CajaModal';  

const CajasSucursal = () => {
  const { id } = useParams(); 
  const { cajas, loading, isFetching, error, refetch } = useFetchCajas(id); 
  const navigate = useNavigate();  

  const [showModal, setShowModal] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null);
  
  // Estado para el filtro de búsqueda
  const [searchTerm, setSearchTerm] = useState('');

  // Obtener el nombre de la sucursal del primer registro de cajas (asumiendo que todos pertenecen a la misma sucursal)
  const nombreSucursal = cajas.length > 0 ? cajas[0].sucursal.name : 'Nombre de sucursal no disponible';

  useEffect(() => {
    if (id) {
      refetch(id);
    }
    setShowModal(false); 
    setSelectedBox(null); 
  }, [id]);  

  const formatFechaExacta = (fecha) => {
    if (!fecha) return 'Sin cerrar';
    const [date, time] = fecha.split('T'); 
    const [year, month, day] = date.split('-'); 
    return `${day}-${month}-${year.slice(2)} ${time.split('.')[0]}`;
  };

  const formatBalance = (balance) => {
    if (balance === null || balance === undefined || balance === '') return '-';
    return `$${balance}`;
  };

  const handleShowModal = (idBox) => {
    setSelectedBox(idBox); 
    setShowModal(true);   
  };

  const handleCloseModal = () => {
    setShowModal(false);  
    setSelectedBox(null);  
  };

  const handleVerHistorico = (idBox) => {
    navigate(`/cajas-sucursales/${id}/historial/${idBox}`);
  };

  // Filtrar las cajas por el nombre o apellido del empleado
  const filteredCajas = cajas.filter((caja) => {
    const nombreCompleto = `${caja.empleado.name} ${caja.empleado.lastName}`.toLowerCase();
    return nombreCompleto.includes(searchTerm.toLowerCase());
  });

  if (loading || isFetching) return <div>Cargando cajas...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="cajas-sucursal-container">
      <Card className="cajas-sucursal-card">
        <Card.Body>
          <h3 className="cajas-sucursal-header">
            Cajas de la sucursal {nombreSucursal}
          </h3>
          
          {/* Input para la búsqueda */}
          <Form.Control
            type="text"
            placeholder="Buscar por nombre o apellido"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-3"
          />
          
          <Table striped bordered hover className="cajas-sucursal-table">
            <thead>
              <tr>
                <th>ID Caja</th>
                <th>Nombre</th> 
                <th>Apellido</th> 
                <th>Fecha de Apertura</th>
                <th>Fecha de Cierre</th>
                <th>Balance</th>
                <th>Acciones</th> 
                <th>Ver Histórico</th> 
              </tr>
            </thead>
            <tbody>
              {filteredCajas.map((caja) => (
                <tr key={caja._id}>
                  <td>{caja._id}</td> 
                  <td>{caja.empleado.name}</td> 
                  <td>{caja.empleado.lastName}</td> 
                  <td>{formatFechaExacta(caja.dateOpen)}</td>
                  <td>{formatFechaExacta(caja.dateClose)}</td>
                  <td>{formatBalance(caja.balance)}</td>
                  <td>
                    <a className="link-ver-mas" onClick={() => handleShowModal(caja.idBox)}>
                      Ver Caja
                    </a>
                  </td>
                  <td>
                    <a className="link-ver-mas" onClick={() => handleVerHistorico(caja.idBox)}>
                      Ver Histórico
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {selectedBox && (
        <CajaModal 
          show={showModal} 
          onHide={handleCloseModal} 
          idBox={selectedBox} 
        />
      )}
    </div>
  );
};

export default CajasSucursal;
