import React, { useState } from 'react';
import { Card, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import './AddSucursal.css'; 
import IMG1 from '../../../assets/Sucursal.png';

function AddSucursal() {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [addedSucursal, setAddedSucursal] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!name) {
      setError('Por favor, ingresa el nombre de la sucursal.');
      return;
    }

    const token = localStorage.getItem('token');
    const API_URL = process.env.REACT_APP_URL;

    try {
      const response = await axios.post(
        `${API_URL}/public/v2/Add/Branches`,
        { name },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          }
        }
      );

      if (response.data.ok) {
        setAddedSucursal(name); // Guardar el nombre de la sucursal añadida
        setSuccess('Sucursal añadida exitosamente.');
        setName(''); 
      } else {
        setError(response.data.msg || 'Error al añadir la sucursal.');
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setError(err.response.data.msg);
      } else {
        setError('Error al realizar la petición.');
      }
    }
  };

  const handleAddAnother = () => {
    setSuccess('');
    setAddedSucursal('');
    setName('');
  };

  return (
    <Card className="shadow custom-card-width">
      <Card.Header as="h5" className="text-center" style={{ backgroundColor: '#ffffff', color: '#fd6b0c', borderBottom: 'none' }}>
        Añadir Sucursal
      </Card.Header>
      <Card.Body>
        <div className="text-center">
          <img src={IMG1} alt="Sucursal" style={{ width: '25%', height: 'auto', marginBottom: '20px' }} />
        </div>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && (
          <Alert variant="success">
            {success}
          </Alert>
        )}
        {addedSucursal ? (
          <div className="text-center">
            <h5>Sucursal añadida: {addedSucursal}</h5>
            <Button
              variant="secondary"
              onClick={handleAddAnother}
              className="mt-3"
            >
              Añadir otra sucursal
            </Button>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formSucursalName">
              <Form.Label>Nombre de la Sucursal</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresa el nombre de la sucursal"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="mt-3"
              style={{ backgroundColor: '#FF5100', border: 'none' }}
              onMouseEnter={(e) => e.target.style.backgroundColor = '#FF7043'}
              onMouseLeave={(e) => e.target.style.backgroundColor = '#FF5100'}
              disabled={!name} 
            >
              Añadir Sucursal
            </Button>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
}

export default AddSucursal;
